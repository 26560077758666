import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    constructor(private httpService: HttpService) { }

    getStoreDoc(store_id: string, doc_id: string) {
        return this.httpService.get(`/management/store/${store_id}/get/${doc_id}`).toPromise();
    }

    updateStoreDoc(store_id: string, doc_id: string, body: any) {
        return this.httpService.put(`/management/store/${store_id}/update/${doc_id}`, body).toPromise();
    }

    createStoreDoc(store_id: string, body: any) {
        return this.httpService.post(`/management/store/${store_id}/create/`, body).toPromise();
    }

    deleteStoreDoc(store_id: string, doc_id: string) {
        return this.httpService.delete(`/management/store/${store_id}/delete/${doc_id}`).toPromise();
    }

    queryStoreDocs(store_id: string, query?: string) {
        if (query) {
            return this.httpService.get(`/management/store/${store_id}/query?${query}`).toPromise();
        } else {
            return this.httpService.get(`/management/store/${store_id}/query`).toPromise();
        }
    }


}