import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { NgForm } from '@angular/forms';
import { ManagementService } from 'src/app/services/management.service';
import { Account } from 'src/app/models/management/account';
import { logType } from 'src/app/models/store/pos/log.mock';
import { Store } from 'src/app/models/social/stores';
import { Supplier } from 'src/app/models/management/supplier';
import { Producer } from 'src/app/models/management/producer';

declare var google;

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})

export class SuppliersComponent implements OnInit {
  onUpdate: boolean;
  suppliers: Array<Supplier> = [];
  producers: Array<Producer> = [];
  stores: Array<Store> = [];
  suppliersView: Array<any> = [];

  accounts: any;
  address: any;
  cordinates: any;

  mapZoom: number;
  mapDrag: boolean;

  logo: any;

  constructor(private httpService: HttpService, private managementService: ManagementService) {
    this.onUpdate = false;
    this.accounts = [];
    this.address = { countries: [], cities: [], provinces: [], districts: [], streets: [] };
    this.cordinates = { lat: 0, lng: 0 };
    this.mapZoom = 15;
    this.mapDrag = false;
  }

  ngOnInit() {
    this.fillData();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let supplier_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.managementService.updateDoc('supplier', supplier_id, form.value).then(res => {
        this.getSuppliers();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.managementService.createDoc('supplier', form.value).then(res => {
        this.getSuppliers();
        form.reset();
      });
    }
  }

  getSuppliers() {
    this.managementService.queryDocs('suppliers').then((res: any) => {
      this.suppliers = res;
      this.suppliersView = this.suppliers;
    })
  }

  deleteSupplier(supplier_id: string) {
    this.managementService.deleteDoc('supplier', supplier_id).then(res => {
      this.suppliersView = this.suppliersView.filter(supplier => supplier._id !== supplier_id);
    });
  }

  editSupplier(supplierForm: NgForm, supplier: any) {
    this.onUpdate = true;
    supplierForm.setValue(supplier);
  }

  filterSuppliers(value: string) {
    let regexp = new RegExp(value, 'i');
    this.suppliersView = this.suppliers.filter(({ name }) => name.match(regexp));
  }

  fillData() {
    this.managementService.queryDocs('accounts').then((res: Array<Account>) => {
      this.accounts = res;
    });
    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
    });
    this.managementService.queryDocs('producers').then((res: Array<Producer>) => {
      this.producers = res;

      this.managementService.queryDocs('suppliers').then((res: Array<Supplier>) => {
        this.suppliers = res;
        for (let i = 0; i < this.suppliers.length; i++) {
          const element = this.suppliers[i];
          element['producers'] = this.producers.map(obj => obj._id);
        }
        this.suppliersView = this.suppliers;
      });
    });
  }

  showAddress(address) {
    let GeoCodeApi = new google.maps.Geocoder();
    GeoCodeApi.geocode({ 'address': address }, (results) => {
      this.cordinates = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng()
      }
    });
  }

  setCordinates(event) {
    this.cordinates = event.coords;
  }

  mapLoaded() {
    // this.showAddress('İstanbul, Beşiktaş')
  }

  getAddress(country?, city?, province?, district?, street?) {
    if (country) {
      let countryText = country.options[country.options.selectedIndex].text;
      this.mapZoom = 5;
      if (city) {
        let cityText = city.options[city.options.selectedIndex].text;
        this.mapZoom = 8;
        if (province) {
          let provinceText = province.options[province.options.selectedIndex].text;
          this.mapZoom = 13;
          if (district) {
            this.mapDrag = true;
            let districtText = district.options[district.options.selectedIndex].text;
            this.mapZoom = 15;
            if (street) {
              let streetText = street.options[street.options.selectedIndex].text;
              this.mapZoom = 17;
              this.showAddress(countryText + ', ' + cityText + ', ' + provinceText + ', ' + districtText + ', ' + streetText);
            } else {
              this.httpService.get(`/management/address/${country.value}/${city.value}/${province.value}/${district.value}`).toPromise().then(streets => {
                this.address.streets = streets;
                this.showAddress(countryText + ', ' + cityText + ', ' + provinceText + ', ' + districtText);
              });
            }
          } else {
            this.httpService.get(`/management/address/${country.value}/${city.value}/${province.value}`).toPromise().then(districts => {
              this.address.districts = districts;
              this.showAddress(countryText + ', ' + cityText + ', ' + provinceText);
            });
          }
        } else {
          this.httpService.get(`/management/address/${country.value}/${city.value}`).toPromise().then(provinces => {
            this.address.provinces = provinces;
            this.showAddress(countryText + ', ' + cityText);
          });
        }
      } else {
        this.httpService.get(`/management/address/${country.value}`).toPromise().then(cities => {
          this.address.cities = cities;
          this.showAddress(countryText);
        })
      }
    } else {
      this.httpService.get(`/management/address`).toPromise().then(countries => {
        this.address.countries = countries;
      })
    }
  }

  changeAccount(acc: any) {

  }

}