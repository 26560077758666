import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'price'
})
export class PricePipe implements PipeTransform {

    constructor() { }

    transform(value: number, currency: string): string {

        if (!value) value = 0;

        switch (currency) {
            case "USD":
                return '$ ' + Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            case "TRY":
                return '₺ ' + Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            case "EUR":
                return '€ ' + Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            default:
                throw new Error("Not a valid currency");
        }
    }
}
