import { Component, OnInit, ViewChild } from '@angular/core';
import { DateComponent } from 'src/app/components/date/date.component';
import { Agreement } from 'src/app/models/management/agreement';
import { Company } from 'src/app/models/management/company';
import { Store } from 'src/app/models/social/stores';
import { HttpService } from 'src/app/services/http.service';
import { ManagementService } from 'src/app/services/management.service';

@Component({
  selector: 'app-agreement-trace',
  templateUrl: './agreement-trace.component.html',
  styleUrls: ['./agreement-trace.component.scss']
})
export class AgreementTraceComponent implements OnInit {

  agreements: Array<Agreement> = [];
  agreementsView: Array<Agreement> = [];
  stores: Array<Store> = [];
  companies: Array<Company> = [];

  exampleStore: Store;

  onUpdate: boolean;
  isOneoffFilter: boolean;
  isInstallmentFilter: boolean;
  isCompanyFilter: boolean;
  isStoreFilter: boolean;
  isPaid: boolean;
  isUnpaid: boolean;
  isActive: boolean;
  isPassive: boolean;
  hasPos: boolean;
  hasMenu: boolean;
  hasDevice: boolean;

  startDate: string;
  endDate: string;

  @ViewChild(DateComponent, { static: true }) child: DateComponent;

  constructor(private httpService: HttpService, private managementService: ManagementService) { }

  ngOnInit() {
    this.fillData();
  }

  ngAfterViewInit() {
    this.startDate = this.child.startDate;
    this.endDate = this.child.endDate;
  }

  getDropdownItems(): Array<Store> | Array<Company> {
    if (this.isStoreFilter) {
      return this.stores;
    }
    if (this.isCompanyFilter) {
      return this.companies;
    }
  }

  setDate(date: Array<string>) {
    if (date) {
      this.startDate = date[0];
      this.endDate = date[1];
    }
    else return;
  }

  setFilters(event: { target: { name: string; checked: boolean; }; }) {
    switch (event.target.name) {
      case "store":
        this.isStoreFilter = event.target.checked;
        if (this.isCompanyFilter)
          this.isCompanyFilter = false;
        break;
      case "company":
        this.isCompanyFilter = event.target.checked;
        if (this.isStoreFilter)
          this.isStoreFilter = false;
        break;
      case "active":
        this.isActive = event.target.checked;
        break;
      case "passive":
        this.isPassive = event.target.checked;
        break;
      case "pos":
        this.hasPos = event.target.checked;
        break;
      case "menu":
        this.hasMenu = event.target.checked;
        break;
      case "device":
        this.hasDevice = event.target.checked;
        break;
      default:
        throw new Error("No valid value provided for event.target.name");
    }
  }

  getStores() {
    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
    });
  }

  // getCompanies() {
  //   this.companies = this.httpService.getCompanies();
  //   console.log(this.companies);
  // }

  getAgreements() {
    // this.httpService.getAgreements().subscribe(res => {
    //   this.agreements = res;
    // });
  }

  fillData() {
    // this.httpService.getCompanies().subscribe(res => {
    //   this.companies = res;
    // });

    // this.httpService.getAgreements().subscribe(res => {
    //   this.agreements = res;
    //   this.agreementsView = this.agreements;
    // });

    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
      this.exampleStore = this.stores[0];
    });
  }

  editAgreement() {
  }

  deleteAgreement() {
  }

  // Generate Company and Agreement interface instances in the run time and then get the object values to provide meaningful data
  // Change store to store array in the Company.ts
  getAgreementStore(agreement: Agreement): string {
    // Management, contractor records are missing in db.json
    if (agreement.contractor === undefined) {
      agreement.contractor = this.companies[0];
      agreement.management = this.companies[0];
      if (agreement.contractor.store === undefined)
        agreement.contractor.store[0] = this.exampleStore;
      return agreement.contractor.store[0].name;
    }
  }
}
