import { Component, OnInit } from '@angular/core';
import { Owner } from 'src/app/models/store/owner';
import { NgForm } from '@angular/forms';
import { ManagementService } from 'src/app/services/management.service';
import { Store } from 'src/app/models/social/stores';
import { Account } from 'src/app/models/management/account';

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.scss']
})
export class OwnersComponent implements OnInit {
  owners: Array<Owner>;
  ownersView: Array<Owner>;
  accounts: Array<Account>;
  stores: Array<Store>;
  onUpdate: boolean;

  avatar: any;

  constructor(private managementService: ManagementService) { }

  ngOnInit() {
    this.onUpdate = false;
    this.fillData();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let owner_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.managementService.updateDoc('owner', owner_id, form.value).then(res => {
        this.fillData();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.managementService.createDoc('owner', form.value).then(res => {
        this.fillData();
        form.reset();
      })
    }
  }

  deleteOwner(owner_id: string) {
    this.managementService.deleteDoc('owner', owner_id).then((res: any) => {
      this.ownersView = this.ownersView.filter(owner => owner._id !== owner_id);
    });
  }

  editOwner(ownerForm: NgForm, owner: Owner) {
    this.onUpdate = true;
    ownerForm.setValue(owner);
  }

  filterOwners(value: string) {
    let regexp = new RegExp(value, 'i');
    this.ownersView = this.owners.filter(({ fullname }) => fullname.match(regexp));
  }

  changeAccount(account_id: string) {
    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
      this.stores = this.stores.filter(obj => {
        return obj.accounts.includes(account_id);
      })
    })
  }

  fillData() {
    this.managementService.queryDocs('owners').then((res: Array<Owner>) => {
      this.owners = res;
      this.ownersView = this.owners;
    });
    this.managementService.queryDocs('accounts').then((res: Array<Account>) => {
      this.accounts = res;
    })
    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
    })
  }
}
