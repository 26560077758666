import { Component, OnInit } from '@angular/core';
import { ManagementService } from 'src/app/services/management.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-comment',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactMessages: Array<string>;
  onUpdate: boolean;

  constructor(private storeService: StoreService, private managementService: ManagementService) { }

  ngOnInit() {
  }
}
