import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  constructor(private httpService: HttpService) { }


  getDoc(endpoint: string, doc_id: string) {
    return this.httpService.get(`/management/${endpoint}/${doc_id}`).toPromise();
  }

  updateDoc(endpoint: string, doc_id: string, body: any) {
    return this.httpService.put(`/management/${endpoint}/${doc_id}`, body).toPromise();
  }

  createDoc(endpoint: string, body: any) {
    return this.httpService.post(`/management/${endpoint}`, body).toPromise();
  }

  deleteDoc(endpoint: string, doc_id: string) {
    return this.httpService.delete(`/management/${endpoint}/${doc_id}`).toPromise();
  }

  queryDocs(endpoint: string, query?: string) {
    if (query) {
      return this.httpService.get(`/management/${endpoint}?${query}`).toPromise();
    } else {
      return this.httpService.get(`/management/${endpoint}`).toPromise();
    }
  }

}


export const placeOptions = [
  "Açık Hava",
  "Isıtıcı",
  "Klima",
  "Otopark",
  "Vale",
  "Manzara"
]

export const categories = [
  "PUB",
  "BISTRO",
  "COFFEESHOP",
  "APPERTIZERS",
  "PIZZA",
  "SEAFOOD",
  "SOUP",
  "SALAD",
  "PASTA",
  "DESERT",
  "STEAK",
  "VEGETARIAN",
  "FASTFOOD",
  "CHICKEN",
  "SUSHI",
  "SPICYFOOD",
  "NOODLES",
  "FISHDISHED",
  "FRUIT",
  "COCKTAIL",
  "BREAKFAST",
  "NIGHTCLUB",
  "MUSICHALL",
  "BAKERY"
]

export const cuisines = [
  "Ainu",
  "Albanian",
  "Argentina",
  "Andhra",
  "AngloIndian",
  "Arab",
  "Armenian",
  "Assyrian",
  "Awadhi",
  "Azerbaijani",
  "Balochi",
  "Belarusian",
  "Bengali",
  "Berber",
  "Buddhist",
  "Bulgarian",
  "Cajun",
  "Chechen",
  "Chinese",
  "ChineseIslamic",
  "Circassian",
  "CrimeanTatar",
  "Danish",
  "Estonian",
  "French",
  "Filipino",
  "Georgian",
  "Goan",
  "GoanCatholic",
  "Greek",
  "Hyderabad",
  "Indian",
  "IndianChinese",
  "IndianSingaporean",
  "Indonesian",
  "Inuit",
  "ItalianAmerican",
  "Italian",
  "Japanese",
  "Jewish",
  "Karnataka",
  "Kazakh",
  "Keralite",
  "Korean",
  "Kurdish",
  "Laotian",
  "Latvian",
  "Lithuanian",
  "LouisianaCreole",
  "Maharashtrian",
  "Mangalorean",
  "Malay",
  "MalaysianChinese",
  "MalaysianIndian",
  "Mediterranean",
  "Mexican",
  "Mordovian",
  "Mughal",
  "NativeAmerican",
  "Nepalese",
  "NewMexican",
  "Odia",
  "Parsi",
  "Pashtun",
  "Polish",
  "PennsylvaniaDutch",
  "Pakistani",
  "Peranakan",
  "Persian",
  "Peruvian",
  "Portuguese",
  "Punjabi",
  "Rajasthani",
  "Romanian",
  "Russian",
  "Sami",
  "Serbian",
  "Sindhi",
  "Slovak",
  "Slovenian",
  "Somali",
  "SouthIndian",
  "SriLankan",
  "Tatar",
  "Thai",
  "Turkish",
  "Tamil",
  "Udupi",
  "Ukrainian",
  "Yamal",
  "Zanzibari"
]
