import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ManagementService } from 'src/app/services/management.service';
import { Store } from 'src/app/models/management/store';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  stores: Array<Store> = [];
  menu: any;

  onUpdate: boolean;

  constructor(private managementService: ManagementService, private httpService: HttpService) { }

  ngOnInit() {
    this.getStores();
  }

  changeStore(store_id: string) {
    this.httpService.get('/menu/' + store_id).subscribe((res: any) => {
      this.menu = res.menu;
    })
  }

  selectFloor(floor: any) {
    // this.selectedFloor = floor._id;
    // this.tablesView = this.tables.filter(({ floor_id }) => floor_id == floor._id);
  }

  getProduct(name: string) {

  }

  getStores() {
    this.managementService.queryDocs('stores').then((stores: Array<Store>) => {
      this.stores = stores;
    });
  }

  submitForm(form: any) {

  }
}
