import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { DatabasesComponent } from './pages/databases/databases.component';
import { UsersComponent } from './pages/users/users.component';
import { StoresComponent } from './pages/stores/stores.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { OwnersComponent } from './pages/owners/owners.component';
import { AuthGuard } from './guards/auth.guard';
import { AddressesComponent } from './pages/addresses/addresses.component';
import { SuppliersComponent } from './pages/suppliers/suppliers.component';
import { ProductsComponent } from './pages/products/products.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { MapComponent } from './pages/map/map.component';
import { PicturesComponent } from './pages/pictures/pictures.component';
import { ProducersComponent } from './pages/producers/producers.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { SubCategoriesComponent } from './pages/sub-categories/sub-categories.component';
import { BrandsComponent } from './pages/brands/brands.component';
import { FloorPlanComponent } from './pages/floorplan/floorplan.component';
import { MenuComponent } from './pages/menu/menu.component';
import { SessionsComponent } from './pages/sessions/sessions.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { AgreementsComponent } from './pages/agreements/agreements.component';
import { InvoiceTraceComponent } from './pages/invoice-trace/invoice-trace.component';
import { AgreementTraceComponent } from './pages/agreement-trace/agreement-trace.component';
import { CommentComponent } from './pages/comment/comment.component';
import { ContactComponent } from './pages/contact/contact.component';

const routes: Routes = [
  { path: '', redirectTo: 'map', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'accounts', component: AccountsComponent, canActivate: [AuthGuard] },
  { path: 'databases', component: DatabasesComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'owners', component: OwnersComponent, canActivate: [AuthGuard] },
  { path: 'stores', component: StoresComponent, canActivate: [AuthGuard] },
  { path: 'suppliers', component: SuppliersComponent, canActivate: [AuthGuard] },
  { path: 'addresses', component: AddressesComponent, canActivate: [AuthGuard] },
  { path: 'producers', component: ProducersComponent, canActivate: [AuthGuard] },
  { path: 'brands', component: BrandsComponent, canActivate: [AuthGuard] },
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
  { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },
  { path: 'campaigns', component: CampaignsComponent, canActivate: [AuthGuard] },
  { path: 'sub_categories', component: SubCategoriesComponent, canActivate: [AuthGuard] },
  { path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'agreements', component: AgreementsComponent, canActivate: [AuthGuard] },
  { path: 'pictures', component: PicturesComponent, canActivate: [AuthGuard] },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: 'comments', component: CommentComponent, canActivate: [AuthGuard] },
  { path: 'map', component: MapComponent, canActivate: [AuthGuard] },
  { path: 'floorplan', component: FloorPlanComponent, canActivate: [AuthGuard] },
  { path: 'menu', component: MenuComponent, canActivate: [AuthGuard] },
  { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard] },
  { path: 'sessions', component: SessionsComponent, canActivate: [AuthGuard] },
  { path: 'invoice-trace', component: InvoiceTraceComponent, canActivate: [AuthGuard] },
  { path: 'agreement-trace', component: AgreementTraceComponent, canActivate: [AuthGuard] },
  { path: 'contact', component: ContactComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
