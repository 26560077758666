import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Account } from 'src/app/models/management/account';
import { Producer } from 'src/app/models/management/producer';
import { Supplier } from 'src/app/models/management/supplier';
import { ManagementService } from 'src/app/services/management.service';

@Component({
  selector: 'app-producers',
  templateUrl: './producers.component.html',
  styleUrls: ['./producers.component.scss']
})
export class ProducersComponent implements OnInit {
  onUpdate: boolean;
  accounts: Array<Account>;
  producers: Array<Producer>;
  producersView: Array<Producer>;

  suppliers: Array<Supplier>;

  logo: any;

  constructor(private managementService: ManagementService) {
    this.onUpdate = false;
    this.producers = [];
    this.suppliers = [];
    this.producersView = [];
  }

  ngOnInit() {
    this.fillData();
    this.getSuppliers();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let producer_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.managementService.updateDoc('producer', producer_id, form.value).then(res => {
        this.getProducers();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.managementService.createDoc('producer', form.value).then(res => {
        this.getProducers();
        form.reset();
      });
    }
  }

  getProducers() {
    this.managementService.queryDocs('producers').then((res: any) => {
      this.producers = res;
      for (let i = 0; i < this.producers.length; i++) {
        const element = this.producers[i];
        element["suppliers"] = this.suppliers;
      }
      this.producersView = this.producers;
    })
  }

  getSuppliers() {
    this.managementService.queryDocs('suppliers').then((res: any) => {
      this.suppliers = res;
    })
  }

  deleteProducer(producer_id: string) {
    this.managementService.deleteDoc('producer', producer_id).then(res => {
      this.producersView = this.producersView.filter(producer => producer._id !== producer_id);
    });
  }

  editProducer(producerForm: NgForm, producer: Producer) {
    this.onUpdate = true;
    producerForm.setValue(producer);
  }

  filterProducers(value: string) {
    let regexp = new RegExp(value, 'i');
    this.producersView = this.producers.filter(({ name }) => name.match(regexp));
  }

  fillData() {
    this.managementService.queryDocs('accounts').then((res: Array<Account>) => {
      this.accounts = res;
    });
    this.getProducers();
  }

  changeAccount(acc: any) {

  }

}