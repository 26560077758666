import { Component, OnInit } from '@angular/core';
import { ManagementService } from 'src/app/services/management.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  onUpdate: boolean;
  producers: Array<any>;
  brands: Array<any>;
  brandsView: Array<any>;

  logo: any;

  constructor(private managementService: ManagementService) {
    this.onUpdate = false;
    this.brands = [];
    this.brandsView = [];
  }

  ngOnInit() {
    this.fillData();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let brand_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.managementService.updateDoc('brand', brand_id, form.value).then(res => {
        this.getBrands();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.managementService.createDoc('brand', form.value).then(res => {
        this.getBrands();
        form.reset();
      });
    }
  }

  getBrands() {
    this.managementService.queryDocs('brands').then((res: any) => {
      this.brands = res;
      this.brandsView = this.brands;
    })
  }

  deleteBrand(brand_id: string) {
    this.managementService.deleteDoc('brand', brand_id).then(res => {
      this.brandsView = this.brandsView.filter(brand => brand._id !== brand_id);
    });
  }

  editBrand(brandForm: NgForm, brand: any) {
    this.onUpdate = true;
    brandForm.setValue(brand);
  }


  filterBrands(value: string) {
    let regexp = new RegExp(value, 'i');
    this.brandsView = this.brands.filter(({ name }) => name.match(regexp));
  }


  fillData() {
    this.managementService.queryDocs('producers').then((res: Array<Account>) => {
      this.producers = res;
    });
    this.getBrands();
  }

}
