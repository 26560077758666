import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';


declare var google: any;

@Component({
  selector: 'hq-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class AddressComponent implements OnInit {

  @ViewChild('description', { static: false }) addressDescription: ElementRef;

  address: any;

  mapZoom: number;
  mapDrag: boolean;
  mapCordinates: { lat: number; lng: number; };

  constructor(private httpService: HttpService) {
    this.address = { countries: [], states: [], provinces: [], districts: [], streets: [] };
    this.mapCordinates = { lat: 0, lng: 0 }
    this.mapZoom = 15;
    this.mapDrag = false;
  }

  ngOnInit() {

  }

  ngOnChanges(){
    
  }

  getAddress(country?, state?, province?, district?, street?) {
    if (country) {
      let countryText: string = country.options[country.options.selectedIndex].text;
      this.mapZoom = 5;
      if (state) {
        let stateText: string = state.options[state.options.selectedIndex].text;
        this.mapZoom = 8;
        if (province) {
          let provinceText: string = province.options[province.options.selectedIndex].text;
          this.mapZoom = 13;
          if (district) {
            this.mapDrag = true;
            let districtText: string = district.options[district.options.selectedIndex].text;
            this.mapZoom = 15;
            if (street) {
              let streetText = street.options[street.options.selectedIndex].text;
              this.mapZoom = 17;
              this.showAddress(countryText + ', ' + stateText + ', ' + provinceText + ', ' + districtText + ', ' + streetText);

            } else {
              this.httpService.get(`/management/address/${country.value}/${state.value}/${province.value}/${district.value}`).toPromise().then(streets => {
                this.address.streets = streets;
                this.showAddress(countryText + ', ' + stateText + ', ' + provinceText + ', ' + districtText);
              });
            }
          } else {
            this.httpService.get(`/management/address/${country.value}/${state.value}/${province.value}`).toPromise().then(districts => {
              this.address.districts = districts;
              this.showAddress(countryText + ', ' + stateText + ', ' + provinceText);
            });
          }
        } else {
          this.httpService.get(`/management/address/${country.value}/${state.value}`).toPromise().then(provinces => {
            this.address.provinces = provinces;
            this.showAddress(countryText + ', ' + stateText);
          });
        }
      } else {
        this.httpService.get(`/management/address/${country.value}`).toPromise().then(states => {
          this.address.states = states;
          this.showAddress(countryText);
        })
      }
    } else {
      this.httpService.get(`/management/address`).toPromise().then(countries => {
        this.address.countries = countries;
      })
    }
  }

  showAddress(address: string) {
    let GeoCodeApi = new google.maps.Geocoder();
    GeoCodeApi.geocode({ 'address': address }, (results) => {
      this.addressDescription.nativeElement.value = results[0].formatted_address;
      this.mapCordinates = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng()
      }
    });
  }

  setCordinates(event) {
    this.mapCordinates = event.coords;
  }

  mapLoaded() {
    this.showAddress('İstanbul, Beşiktaş');
  }

}
