import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class DateComponent implements OnInit {

  startDate: string;
  endDate: string;

  // Index 0:startDate 1:endDate
  @Input() date: Array<string>;

  constructor() {
  }

  ngOnInit() {
    this.date = [];
    let defaultDate = this.setDefaultDate();
    this.startDate = defaultDate.start;
    this.endDate = defaultDate.end;
  }

  setDefaultDate(): { start: string, end: string } {
    const startDateObj = new Date();
    const endDateObj = new Date(Date.now() + 12096e5);

    let splittedStart = this.toIsoString(startDateObj).split('+');
    let splittedEnd = this.toIsoString(endDateObj).split('+');

    return { start: splittedStart[0], end: splittedEnd[0] };
  }

  // Sets @Input date array
  setDate(event: any) {
    switch (event.target.name) {
      case "start":
        this.startDate = event.target.value;
        break;
      case "expiry":
        this.endDate = event.target.value;
      default:
        break;
    }
    this.date[0] = this.startDate;
    this.date[1] = this.endDate;
  }

  // Helper function that adds timezone offset to the date object.
  toIsoString(date: Date): string {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
  }
}
