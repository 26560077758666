import { Component, OnInit, Input } from '@angular/core';
import Konva from 'konva';
import { ShapeService, TextNodeService } from './blueprint.service';
import { Floor, Table } from 'src/app/models/store/pos/table.mock';
import { CircleConfig } from 'konva/types/shapes/Circle';
import { RectConfig } from 'konva/types/shapes/Rect';
import { TextConfig } from 'konva/types/shapes/Text';

@Component({
  selector: 'app-blueprint',
  templateUrl: './blueprint.component.html',
  styleUrls: ['./blueprint.component.scss']
})

export class BlueprintComponent implements OnInit {

  @Input('floors') floors: Array<Floor>;
  @Input('tables') tables: Array<Table>;

  stage: Konva.Stage;
  layer: Konva.Layer;

  shapes: any = [];
  transformers: Konva.Transformer[] = [];

  selectedButton: any = { 'circle': false, 'rectangle': false, 'line': false, 'undo': false, 'erase': false, 'text': false }
  erase: boolean = false;

  constructor(private shapeService: ShapeService, private textNodeService: TextNodeService) { }

  ngOnInit() {
    let width = window.innerWidth;
    let height = window.innerHeight;

    this.stage = new Konva.Stage({
      container: 'floorplan',
      width: 1024,
      height: 768,
    });

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);
    this.addLineListeners();
    this.fillTables();
  }

  fillTables() {
    this.tables.forEach(table => {
      this.addRectangle({ x: table.position.x, y: table.position.y, height: table.position.height, width: table.position.width, fill: 'orange', stroke: 'orangered', strokeWidth: 1 });
    })
  }

  addTable(table: Table) {
    const rectOpts: RectConfig = { x: 100, y: 100, height: 100, width: 200, fill: '#f0ad4e', stroke: '#1B2731', strokeWidth: 0.5, draggable: false };
    const textOpts: TextConfig = { text: table.name, x: 110, y: 120, width: 200, fontSize: 25, fontFamily: 'Quickly', fill: '#8f5e1b', draggable: false };

    const rectangle = this.shapeService.rectangle(rectOpts);
    const text = this.shapeService.text(textOpts);

    const tableShape = new Konva.Group({ draggable: true });

    tableShape.add(rectangle);
    tableShape.add(text);

    this.shapes.push(tableShape);
    this.layer.add(tableShape);

    this.stage.add(this.layer);

    this.addTransformerListeners()
  }


  addShape(type: string) {
    this.clearSelection();
    this.setSelection(type);
    if (type == 'circle') {
      this.addCircle();
    }
    else if (type == 'line') {
      this.addLine();
    }
    else if (type == 'rectangle') {
      this.addRectangle();
    }
    else if (type == 'text') {
      this.addText();
    }
  }

  addText() {
    const text = this.textNodeService.textNode(this.stage, this.layer);
    this.shapes.push(text.textNode);
    this.transformers.push(text.tr);
  }

  addCircle(opts?: CircleConfig) {
    const circle = this.shapeService.circle(opts);
    this.shapes.push(circle);
    this.layer.add(circle);
    this.stage.add(this.layer);
    this.addTransformerListeners()
  }

  addRectangle(opts?: RectConfig) {
    const rectangle = this.shapeService.rectangle(opts);
    this.shapes.push(rectangle);
    this.layer.add(rectangle);
    this.stage.add(this.layer);
    this.addTransformerListeners()
  }

  addLine() {
    this.selectedButton['line'] = true;
  }

  addLineListeners() {
    const component = this;
    let lastLine;
    let isPaint;
    this.stage.on('mousedown touchstart', function (e) {
      if (!component.selectedButton['line'] && !component.erase) {
        return;
      }
      isPaint = true;
      let pos = component.stage.getPointerPosition();
      const mode = component.erase ? 'erase' : 'brush';
      lastLine = component.shapeService.line(pos, mode)
      component.shapes.push(lastLine);
      component.layer.add(lastLine);
    });
    this.stage.on('mouseup touchend', function () {
      isPaint = false;
    });
    // and core function - drawing
    this.stage.on('mousemove touchmove', function () {
      if (!isPaint) {
        return;
      }
      const pos = component.stage.getPointerPosition();
      var newPoints = lastLine.points().concat([pos.x, pos.y]);
      lastLine.points(newPoints);
      component.layer.batchDraw();
    });
  }


  undo() {
    const removedShape = this.shapes.pop();
    this.transformers.forEach(t => {
      t.detach();
    });
    if (removedShape) {
      removedShape.remove();
    }
    this.layer.draw();
  }


  addTransformerListeners() {
    const component = this;
    const tr = new Konva.Transformer();
    this.stage.on('click', function (e) {
      console.log(e);
      if (!this.clickStartShape) {
        return;
      }
      if (e.target._id == this.clickStartShape._id) {
        component.addDeleteListener(e.target);
        component.layer.add(tr);
        tr.attachTo(e.target);
        component.transformers.push(tr);
        component.layer.draw();
      }
      else {
        tr.detach();
        component.layer.draw();
      }
    });
  }

  addDeleteListener(shape) {
    const component = this;
    window.addEventListener('keydown', function (e) {
      if (e.keyCode === 46) {
        shape.remove();
        component.transformers.forEach(t => {
          t.detach();
        });
        const selectedShape = component.shapes.find(s => s._id == shape._id);
        selectedShape.remove();
        e.preventDefault();
      }
      component.layer.batchDraw();
    });
  }

  clearSelection() {
    Object.keys(this.selectedButton).forEach(key => {
      this.selectedButton[key] = false;
    })
  }

  setSelection(type: string) {
    this.selectedButton[type] = true;
  }

}