import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateComponent } from 'src/app/components/date/date.component';
import { Agreement } from 'src/app/models/management/agreement';
import { Company } from 'src/app/models/management/company';
import { Store } from 'src/app/models/social/stores';
import { HttpService } from 'src/app/services/http.service';
import { ManagementService } from 'src/app/services/management.service';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})

export class AgreementsComponent implements OnInit {

  startDate: string;
  endDate: string;

  management: Company;
  contractor: Company;

  contact: string;
  phoneNumber: string;

  companies: Array<Company> = [];
  agreements: Array<Agreement> = [];
  stores: Array<Store> = [];

  onUpdate: boolean;

  @ViewChild(DateComponent, { static: true }) child: DateComponent;

  constructor(private httpService: HttpService, private managementService: ManagementService) {
  }

  ngOnInit() {
    this.fillData();
    this.getStores();
    this.getAgreements();
  }

  submitForm(form: NgForm, post: boolean) {
    if (post) {
      let invoice_id = form.value._id;
      if (this.onUpdate) {
        delete form.value._id;
        delete form.value._rev;
        this.managementService.updateDoc('agreement', invoice_id, form.value).then(res => {
          // this.fillData();
          form.reset();
          this.onUpdate = false;
        });
      } else {
        this.managementService.createDoc('agreement', form.value).then(res => {
          // this.fillData();
          form.reset();
        });
      }
    } else {

      if (this.startDate === undefined || this.endDate === undefined) {
        this.startDate = this.child.startDate;
        this.endDate = this.child.endDate;
      }

      let agreementItem: Agreement = {
        management: this.management,
        contractor: this.contractor,
        startDate: this.startDate,
        endDate: this.endDate,
        agreementStatus: form.value.status,
        agreementType: form.value.type,
        contact: { name: form.value.contactName, phone_number: form.value.phoneNumber }
      }
      console.log(agreementItem);
    }
  }

  setDate(date: Array<string>) {
    if (date) {
      this.startDate = date[0];
      this.endDate = date[1];
    }
    else return;
  }

  changeCompany(company) {
    this.contractor = company;
  }

  changeStore(store) {
  }

  filterAgreements() {
  }

  getAgreements() {
    // this.httpService.getAgreements().subscribe(res => {
    //   this.agreements = res;
    // });
  }

  getStores() {
    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
    });
  }

  fillData() {
    // this.httpService.getCompanies().subscribe(res => {
    //   this.companies = res;
    //   this.management = this.companies[this.companies.length - 1];
    // });

    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
    });
  }
}
