import { Component, OnInit } from '@angular/core';
import { SubCategory, Category } from 'src/app/models/management/category';
import { HttpService } from 'src/app/services/http.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss']
})
export class SubCategoriesComponent implements OnInit {
  categories: Array<Category>;
  sub_categories: Array<SubCategory>;
  sub_categoriesView: Array<SubCategory>;
  onUpdate: boolean;

  img: any;

  constructor(private httpService: HttpService) { }

  ngOnInit() {
    this.onUpdate = false;
    this.getSubCategories();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let sub_category_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.httpService.put('/management/sub_category/' + sub_category_id, form.value).toPromise().then(res => {
        this.getSubCategories();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.httpService.post('/management/sub_category', form.value).toPromise().then(res => {
        this.getSubCategories();
        form.reset();
      })
    }
  }

  getSubCategories() {
    this.httpService.get('/management/sub_categories').toPromise().then((res: Array<SubCategory>) => {
      this.sub_categories = res;
      this.sub_categoriesView = this.sub_categories;
    });
    this.httpService.get('/management/categories').toPromise().then((res: Array<Category>) => {
      this.categories = res;
    });
  }

  deleteSubCategory(sub_category_id: string) {
    this.httpService.delete('/management/sub_category/' + sub_category_id).toPromise().then((res: any) => {
      this.sub_categoriesView = this.sub_categoriesView.filter(sub_category => sub_category._id !== sub_category_id);
    });
  }

  editSubCategory(sub_categoryForm: NgForm, sub_category: SubCategory) {
    this.onUpdate = true;
    sub_categoryForm.setValue(sub_category);
  }


  filterSubCategories(value: string) {
    let regexp = new RegExp(value, 'i');
    this.sub_categoriesView = this.sub_categories.filter(({ name }) => name.match(regexp));
  }

}
