import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ManagementService } from 'src/app/services/management.service';
import { Product } from 'src/app/models/management/product';
import { Producer } from 'src/app/models/management/producer';
import { Brand } from 'src/app/models/management/brand';
import { Category, SubCategory } from 'src/app/models/management/category';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  onUpdate: boolean;
  products: Array<Product>;
  productsView: Array<Product>;
  producers: Array<Producer>;
  brands: Array<Brand>

  categories: Array<Category>;
  sub_categories: Array<SubCategory>;
  sub_categoriesView: Array<SubCategory>;

  packages: Array<any>;

  producerPlaceholder: string = "Üretici";
  categoryPlaceholder: string = "Kategori";

  timeout: any;
  img: any;

  // categoryPlaceholder: string = "Kategori";
  // producerPlaceholder: string = "Üretici";

  constructor(private managementService: ManagementService) {
    this.onUpdate = false;
    this.products = [];
    this.productsView = [];
    this.packages = [];
  }

  ngOnInit() {
    this.fillData();
  }

  submitForm(form: NgForm) {
    if (typeof form.value.ingredients == 'string') {
      form.value.ingredients = form.value.ingredients.split(',');
    }
    if (typeof form.value.tags == 'string') {
      form.value.tags = form.value.tags.split(',');
    }
    form.value.packages = this.packages;
    if (this.onUpdate) {
      let product_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.managementService.updateDoc('product', product_id, form.value).then(res => {
        form.reset();
        this.onUpdate = false;
        this.packages = [];
      }).catch(err => {
        console.log(err);
      })
    } else {
      this.managementService.createDoc('product', form.value).then(res => {
        form.reset();
        this.packages = [];
      }).catch(err => {
        console.log(err);
      });
    }
  }

  getProducts(limit: number, fetch_limit: number) {
    for (let i = 0; i < limit; i += fetch_limit) {
      this.managementService.queryDocs('products', 'limit=' + fetch_limit + '&skip=' + i).then((res: any) => {
        this.products = this.products.concat(res);

        console.log(this.products);

        // this.productsView = this.productsView.concat(res);
      });
    }
  }

  changeProducer(producer_id: string) {
    this.managementService.queryDocs('brands', `producer_id=${producer_id}`).then((brands: Array<Brand>) => {
      this.brands = brands;
    })
  }

  changeCategory(category_id: string) {
    this.managementService.queryDocs('sub_categories', `category_id=${category_id}`).then((sub_cats: Array<SubCategory>) => {
      this.sub_categories = sub_cats;
    })
  }

  deleteProduct(product_id: string) {
    this.managementService.deleteDoc('product', product_id).then(res => {
      this.productsView = this.productsView.filter(product => product._id !== product_id);
    });
  }

  editProduct(productForm: NgForm, product: any) {
    this.onUpdate = true;
    if (product.packages) this.packages = product.packages;
    try {
      productForm.setValue(product);
    } catch (error) {
      delete product.calorie;
      if (!product.packages) product.packages = [];
      if (!product.sku) product.sku = '';
      if (!product.brand_id) product.brand_id = '';
      if (!product.type) product.type = 0;
      if (!product.channel) product.channel = 0;
      if (!product.status) product.status = 0;
      productForm.setValue(product);
    }
  }



  // filterProductsByProducer(value:string){
  //   console.log(value);
  //   this.productsView = this.products.filter(({ producer_id }) => producer_id == value);
  // }

  // filterProductsByBarcode(value:string){
  //   clearTimeout(this.timeout);
  //   this.timeout = setTimeout(() => {
  //     let regexp = new RegExp(value, 'i');
  //     this.productsView = this.products.filter(({ barcode }) => barcode.toString().match(regexp));
  //   }, 500);
  // }

  addPackage(name: string, quantity: number) {
    this.packages.push({ name: name, quantity: quantity });
  }

  fillData() {
    this.managementService.queryDocs('producers').then((res: Array<Producer>) => {
      this.producers = res;
    });
    this.managementService.queryDocs('categories').then((res: Array<Category>) => {
      this.categories = res;
      console.log(this.categories);
    });
    this.managementService.queryDocs('sub_categories').then((res: Array<SubCategory>) => {
      this.sub_categories = res;
      console.log(this.sub_categories);

    });
    this.getProducts(6000, 100);
  }


  filterProducts(value: string) {
    if(value != ''){
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        let regexp = new RegExp(value, 'i');
        this.productsView = this.products.filter(({ name }) => name.match(regexp));
      }, 500);
    }
  }

  filterProductsByProducer(value: string) {
    this.productsView = this.products.filter(({ producer_id }) => producer_id == value);
  }

  filterProductsByCategory(value: string) {
    this.productsView = this.products.filter(({ category }) => category == value);
  }

  filterProductsByBarcode(value: number) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let regexp = new RegExp(value.toString(), 'i');
      this.productsView = this.products.filter(({ barcode }) => barcode.toString().match(regexp));
    }, 500);
  }
}