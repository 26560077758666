import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { Session } from 'src/app/models/management/session';
import { ManagementService } from 'src/app/services/management.service';
import { NgForm } from '@angular/forms';
import { User } from 'src/app/models/management/users';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {
  onUpdate: boolean;

  users: Array<User>;
  sessions: Array<Session>;
  sessionsView: Array<Session>;


  constructor(private managementService: ManagementService) {
    this.onUpdate = false;
  }

  ngOnInit() {
    this.fillData();
  }

  getBrands() {
    this.managementService.queryDocs('session').then((res: any) => {
      this.sessions = res;
      this.sessionsView = this.sessions;
    })
  }

  deleteBrand(brand_id: string) {
    this.managementService.deleteDoc('session', brand_id).then(res => {
      this.sessionsView = this.sessionsView.filter(brand => brand._id !== brand_id);
    });
  }

  editBrand(sessionForm: NgForm, brand: any) {
    this.onUpdate = true;
    sessionForm.setValue(brand);
  }


  filterBrands(value: string) {
    let regexp = new RegExp(value, 'i');
    this.sessionsView = this.sessions.filter(({ _id }) => _id.match(regexp));
  }


  fillData() {
    // this.managementService.queryDocs('users').then((res: any) => {
    //   this.users = res;
    // })
    this.managementService.queryDocs('sessions').then((res: any) => {
      this.sessions = res;
      this.sessionsView = this.sessions;
    })
  }

}
