import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map } from 'rxjs/operators';
import { User } from '../models/management/users';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authStatus: Observable<boolean>;
  isAuthenticated: BehaviorSubject<boolean>

  constructor(private httpService: HttpService, private storageService: StorageService, private router: Router) {
    this.isAuthenticated = new BehaviorSubject(false);
    this.authStatus = this.isAuthenticated.asObservable();
  }

  logIn(username: string, password: string) {
    return this.httpService.post('/management/auth/login', { username: username, password: password }).pipe(map((res: any) => {
      if (res.ok) {
        this.storageService.set('AccessToken', res.token);
        this.verifyToken();
      }
      return res;
    }));
  }

  logOut() {
    this.httpService.post('/management/auth/logout').toPromise().then(res => {
      this.isAuthenticated.next(false);
      this.storageService.clear();
      this.router.navigate(['/login'])
    })
  }

  verifyToken() {
    this.httpService.post('/management/auth/verify').toPromise().then((res: any) => {
      this.isAuthenticated.next(true);
      this.storageService.set('AccessData', res.data);
      this.setCurrentUser(res.data.user_id);
    }).catch(err => {
      this.isAuthenticated.next(false);
      this.storageService.clear();
      this.router.navigate(['/login'])
    });
  }

  setCurrentUser(user_id: string) {
    this.httpService.get(`/management/user/${user_id}`).toPromise().then((res: User) => {
      this.storageService.set('CurrentUser', res);
    }).catch(err => {
      this.isAuthenticated.next(false);
      this.storageService.clear();
    })
  }

  getCurrentUser(): User {
    return this.storageService.get('CurrentUser');
  }


}
