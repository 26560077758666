import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})

export class HttpService {
  headers: HttpHeaders;
  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = 'https://hq.quickly.com.tr';
    // this.baseUrl = 'http://localhost:3000';
    // this.baseUrl = 'http://192.168.1.12:3000';
  }

  get(url) {
    return this.http.get(this.baseUrl + url);
  }

  post(url, data?) {
    return this.http.post(this.baseUrl + url, data);
  }

  put(url, data?) {
    return this.http.put(this.baseUrl + url, data);
  }

  delete(url) {
    return this.http.delete(this.baseUrl + url);
  }

  getCurreny() {
    // return this.http.get(url);
    return this.http.get(this.baseUrl + '/management/utils/currency').toPromise();
  }
}
