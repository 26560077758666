import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isLoading: boolean;
  message: string;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.isLoading = false;
  }

  login(loginForm: NgForm) {
    this.isLoading = true;
    this.authService.logIn(loginForm.value.username, loginForm.value.password).subscribe((res: any) => {
      this.isLoading = false;
      this.message = res.message;
      setTimeout(() => this.router.navigate(['']), 500)
    }, err => {
      this.isLoading = false;
      this.message = err.error.message;
      loginForm.reset();
    });
  }
}
