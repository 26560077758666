import { Component, OnInit } from '@angular/core';
import { Comment } from 'src/app/models/social/comments';
import { Store } from 'src/app/models/social/stores';
import { ManagementService } from 'src/app/services/management.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  stores: Array<Store>;
  comments: Array<Comment>;
  onUpdate: boolean;
  commentsView: any;

  hasStoreSelected: boolean;

  constructor(private storeService: StoreService, private managementService: ManagementService) { }

  ngOnInit() {
    this.stores = [];
    this.comments = [];
    this.hasStoreSelected = false;
    this.getStores();
  }

  changeStore(event: any) {
    switch (event) {
      case undefined:
        this.hasStoreSelected = false;
        break;
      case event:
        this.hasStoreSelected = true;
        let storeId = event._id;
        this.storeService.queryStoreDocs(storeId, 'db_name=comments').then((res: Array<Comment>) => {
          this.comments = res;
          this.commentsView = this.comments;
          this.commentsView.forEach((comment: { description: string; }) => {
            if (comment.description === null || comment.description === "")
              this.commentsView.splice(this.commentsView.indexOf(comment));
          });
        });
        break;
      default:
        break;
    }
  }

  // editComment(comment: Comment) {
  //   this.commentsView = this.comments.filter(obj => obj.comment == comment._id);
  // }

  getStores() {
    this.managementService.queryDocs('stores').then((stores: Array<Store>) => {
      this.stores = stores;
    });
  }

  deleteComment(event) {

    console.log(event);

    // return this.storeService.deleteStoreDoc(`/management/store/${store_id}/delete/${doc_id}`,).toPromise();
  }

  // deleteStoreDoc(store_id: string, doc_id: string) {
  //   return this.httpService.delete(`/management/store/${store_id}/delete/${doc_id}`).toPromise();
  // }

  // deleteComment() {
  //   this.managementService.deleteDoc('store', store_id).then((res: any) => {
  //     this.commentsView = this.commentsView.filter(store => store._id !== store_id);
  //   });
  // }
}