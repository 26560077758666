import { Component, OnInit } from '@angular/core';
import { Account } from 'src/app/models/management/account';
import { HttpService } from 'src/app/services/http.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  accounts: Array<Account>;
  accountsView: Array<Account>;
  onUpdate: boolean;

  constructor(private httpService: HttpService) { }

  ngOnInit() {
    this.onUpdate = false;
    this.getAccounts();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let account_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.httpService.put('/management/account/' + account_id, form.value).toPromise().then(res => {
        this.getAccounts();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.httpService.post('/management/account', form.value).toPromise().then(res => {
        this.getAccounts();
        form.reset();
      })
    }
  }

  getAccounts() {
    this.httpService.get('/management/accounts').toPromise().then((res: Array<Account>) => {
      this.accounts = res;
      this.accountsView = this.accounts;
    });
  }

  deleteAccount(account_id: string) {
    this.httpService.delete('/management/account/' + account_id).toPromise().then((res: any) => {
      this.accountsView = this.accountsView.filter(account => account._id !== account_id);
    });
  }

  editAccount(accountForm: NgForm, account: Account) {
    this.onUpdate = true;
    accountForm.setValue(account);
  }


  filterAccounts(value: string) {
    let regexp = new RegExp(value, 'i');
    this.accountsView = this.accounts.filter(({ name }) => name.match(regexp));
  }
}
