import { Component, OnInit, ViewChild } from '@angular/core';
import { Company } from 'src/app/models/management/company';
import { Store } from 'src/app/models/social/stores';
import { DateComponent } from 'src/app/components/date/date.component';
import { Invoice, InvoiceItem } from 'src/app/models/management/invoice';
import { HttpService } from 'src/app/services/http.service';
import { ManagementService } from 'src/app/services/management.service';

@Component({
  selector: 'app-invoice-trace',
  templateUrl: './invoice-trace.component.html',
  styleUrls: ['./invoice-trace.component.scss']
})

export class InvoiceTraceComponent implements OnInit {

  invoices: Array<Invoice> = [];
  invoiceView: Array<Invoice> = [];
  stores: Array<Store> = [];
  companies: Array<Company> = [];

  onUpdate: boolean;

  isOneoffFilter: boolean;
  isInstallmentFilter: boolean;
  isCompanyFilter: boolean;
  isStoreFilter: boolean;
  isPaid: boolean;
  isUnpaid: boolean;
  isPos: boolean;
  isMenu: boolean;
  isDevice: boolean;

  startDate: string;
  endDate: string;

  @ViewChild(DateComponent, { static: true }) child: DateComponent;

  constructor(private httpService: HttpService, private managementService: ManagementService) { }

  ngOnInit() {
    this.fillData();
  }

  ngAfterViewInit() {
    this.startDate = this.child.startDate;
    this.endDate = this.child.endDate;
  }

  getDropdownItems(): Array<Store> | Array<Company> {
    if (this.isStoreFilter) {
      return this.stores;
    }
    if (this.isCompanyFilter) {
      return this.companies;
    }
  }

  setDate(date: Array<string>) {
    if (date) {
      this.startDate = date[0];
      this.endDate = date[1];
    }
    else return;
  }

  setFilter(event: { target: { name: string; checked: boolean; }; }) {
    switch (event.target.name) {
      case "store":
        this.isStoreFilter = event.target.checked;
        if (this.isCompanyFilter)
          this.isCompanyFilter = false;
        break;
      case "company":
        this.isCompanyFilter = event.target.checked;
        if (this.isStoreFilter)
          this.isStoreFilter = false;
        break;
      case "oneoff":
        this.isOneoffFilter = event.target.checked;
        break;
      case "installment":
        this.isInstallmentFilter = event.target.checked;
        break;
      case "paid":
        this.isPaid = event.target.checked;
        break;
      case "unpaid":
        this.isUnpaid = event.target.checked;
        break;
      case "pos":
        this.isPos = event.target.checked;
        break;
      case "menu":
        this.isMenu = event.target.checked;
        break;
      case "device":
        this.isDevice = event.target.checked;
        break;
      default:
        throw new Error("No valid value provided for event.target.name");
    }
  }

  fillData() {
    // this.httpService.getCompanies().subscribe(res => {
    //   this.companies = res;
    // });

    // this.httpService.getInvoices().subscribe(res => {
    //   this.invoices = res;
    //   this.invoiceView = this.invoices;
    // })

    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
    });
  }
  editInvoice() {
  }

  deleteInvoice() {
  }

  getItems(invoice: Invoice): string {
    let items: Array<InvoiceItem> = invoice.items;

    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      return element.name;
    }
  }
}