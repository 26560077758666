import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { Store } from 'src/app/models/social/stores';
import { MapTypeStyle } from '@agm/core/services/google-maps-types';

declare var google: any

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit {
  mapZoom: number;
  mapDrag: boolean;
  mapStyle: Array<MapTypeStyle>;
  cordinates: any;
  address: any
  stores: Array<any>;
  storesView: Array<any>;
  previous;
  icon: { url: string; scaledSize: { height: number; width: number; }; };

  constructor(private httpService: HttpService) {
    this.icon = { url: '/assets/icons/icon.png', scaledSize: { height: 60, width: 60 } };
    this.address = { countries: [], cities: [], provinces: [], districts: [], streets: [] };
    this.cordinates = { lat: 41.0422066, lng: 29.006703700000003 }
    this.mapZoom = 11;
    this.mapDrag = false;

    this.mapStyle = [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#242f3e"
          }
        ]
      },
      {
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#746855"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#242f3e"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#d59563"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#242f3e"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#5cb85c"
          },
          {
            "saturation": 65
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#d59563"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#263c3f"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#6b9a76"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#38414e"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#212a37"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#9ca5b3"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#746855"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#1f2835"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#f3d19c"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#2f3948"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#d59563"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#17263c"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#515c6d"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#17263c"
          }
        ]
      }
    ]
  }

  ngOnInit() {
    this.httpService.get('/management/stores').toPromise().then((res: Array<Store>) => {
      // this.stores = res.map(obj => obj.address.cordinates);
      this.stores = res;
      this.storesView = this.stores;
      console.log(this.stores);
    });
  }


  getAddress(country?, city?, province?, district?, street?) {
    if (country) {
      let countryText = country.options[country.options.selectedIndex].text;
      this.mapZoom = 5;
      if (city) {
        let cityText = city.options[city.options.selectedIndex].text;
        this.mapZoom = 8;
        if (province) {
          let provinceText = province.options[province.options.selectedIndex].text;
          this.mapZoom = 13;
          if (district) {
            this.mapDrag = true;
            let districtText = district.options[district.options.selectedIndex].text;
            this.mapZoom = 15;
            if (street) {
              let streetText = street.options[street.options.selectedIndex].text;
              this.mapZoom = 17;
              this.showAddress(countryText + ', ' + cityText + ', ' + provinceText + ', ' + districtText + ', ' + streetText);
            } else {
              this.httpService.get(`/management/address/${country.value}/${city.value}/${province.value}/${district.value}`).toPromise().then(streets => {
                this.address.streets = streets;
                this.showAddress(countryText + ', ' + cityText + ', ' + provinceText + ', ' + districtText);
              });
            }
          } else {
            this.httpService.get(`/management/address/${country.value}/${city.value}/${province.value}`).toPromise().then(districts => {
              this.address.districts = districts;
              this.showAddress(countryText + ', ' + cityText + ', ' + provinceText);
            });
          }
        } else {
          this.httpService.get(`/management/address/${country.value}/${city.value}`).toPromise().then(provinces => {
            this.address.provinces = provinces;
            this.showAddress(countryText + ', ' + cityText);
          });
        }
      } else {
        this.httpService.get(`/management/address/${country.value}`).toPromise().then(cities => {
          this.address.cities = cities;
          this.showAddress(countryText);
        })
      }
    } else {
      this.httpService.get(`/management/address`).toPromise().then(countries => {
        this.address.countries = countries;
      })
    }
  }


  filterStores(value: string) {
    let regexp = new RegExp(value, 'i');
    this.storesView = this.stores.filter(({ name }) => name.match(regexp));
  }

  storeDetail(infowindow: any) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  showAddress(address) {
    let GeoCodeApi = new google.maps.Geocoder();
    GeoCodeApi.geocode({ 'address': address }, (results) => {
      this.cordinates = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng()
      }
    });
  }

  setCordinates(event) {
    this.cordinates = event.coords;
  }

  mapLoaded() {
    // this.showAddress('İstanbul, Beşiktaş')
  }


}
