import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper'

@Component({
  selector: 'hq-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})

export class ImageComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  @Output() imageData = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imageData.emit(event.base64);
  }
  imageCropped(event: ImageCroppedEvent) {
    this.imageData.emit(event.base64);
  }
  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
    // show message
  }

}
