import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { User } from 'src/app/models/management/users';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: Array<User>;
  usersView: Array<User>;
  onUpdate: boolean;
  avatar: any;
  
  constructor(private httpService: HttpService) { }

  ngOnInit() {
    this.onUpdate = false;
    this.getUsers();
    this.getGroups();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let user_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.httpService.put('/management/user/' + user_id, form.value).toPromise().then(res => {
        this.getUsers();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.httpService.post('/management/user', form.value).toPromise().then(res => {
        this.getUsers();
        form.reset();
      })
    }
  }

  getUsers() {
    this.httpService.get('/management/users').toPromise().then((res: Array<User>) => {
      this.users = res;
      this.usersView = this.users;
    });
  }

  deleteUser(user_id: string) {
    this.httpService.delete('/management/user/' + user_id).toPromise().then((res: any) => {
      this.usersView = this.usersView.filter(user => user._id !== user_id);
    });
  }

  editUser(userForm: NgForm, user: User) {
    this.onUpdate = true;
    userForm.setValue(user);
  }

  getGroups() {
    this.httpService.get('/management/groups').toPromise().then((res: Array<User>) => {
      console.log(res);
    });
  }

  filterUsers(value: string) {
    let regexp = new RegExp(value, 'i');
    this.usersView = this.users.filter(({ fullname }) => fullname.match(regexp));
  }

}
