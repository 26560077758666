import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'hq-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  navbarMenu: Array<any>;

  constructor(public authService: AuthService) {
    this.navbarMenu = [
      {
        name: 'İşletmeler', url: '/stores', subMenu: [
          { name: 'İşletmeler', url: '/stores' },
          { name: 'Şirketler', url: '/companies' },
          { name: 'Sahipler', url: '/owners' },
          { name: 'Harita', url: '/map' },
          { name: 'Faturalar', url: '/invoices' },
          { name: 'Resimler', url: '/pictures' },
          { name: 'Siparişler', url: '/orders' },
          { name: 'Yorumlar', url: '/comments' }
        ]
      },
      {
        name: 'Market', url: '/products', subMenu: [
          { name: 'Üreticiler', url: '/producers' },
          { name: 'Markalar', url: '/brands' },
          { name: 'Tedarikçiler', url: '/suppliers' },
          { name: 'Ürünler', url: '/products' },
          { name: 'Kategoriler', url: '/categories' },
          { name: 'Alt Kategoriler', url: '/sub_categories' },
          { name: 'Kampanyalar', url: '/campaigns' }
        ]
      },
      {
        name: 'Admin', url: '/admin', subMenu: [
          { name: 'Panel', url: '/home' },
          { name: 'Kullanıcılar', url: '/users' },
          { name: 'Hesaplar', url: '/accounts' },
          { name: 'Faturalandırma', url: '/billing' }
        ]
      },
      {
        name: 'Takip', url: '/takip', subMenu: [
          { name: 'Fatura Takip', url: '/invoice-trace' },
          { name: 'Sözleşme Takip', url: '/agreement-trace' },
          { name: 'İletişim', url: '/contact' },
        ]
      }
    ]
  }

  ngOnInit() {

  }
}