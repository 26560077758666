import { Component, OnInit } from '@angular/core';
import { Database } from 'src/app/models/management/database';
import { HttpService } from 'src/app/services/http.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-databases',
  templateUrl: './databases.component.html',
  styleUrls: ['./databases.component.scss']
})
export class DatabasesComponent implements OnInit {
  databases: Array<Database>;
  databasesView: Array<Database>;
  onUpdate: boolean;


  constructor(private httpService: HttpService) { }

  ngOnInit() {
    this.onUpdate = false;
    this.getDatabases();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let database_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.httpService.put('/management/database/' + database_id, form.value).toPromise().then(res => {
        this.getDatabases();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.httpService.post('/management/database', form.value).toPromise().then(res => {
        this.getDatabases();
        form.reset();
      })
    }
  }


  deleteDatabase(account_id: string) {
    this.httpService.delete('/management/database/' + account_id).toPromise().then((res: any) => {
      this.databasesView = this.databasesView.filter(account => account._id !== account_id);
    });
  }

  editDatabase(accountForm: NgForm, account: Account) {
    this.onUpdate = true;
    accountForm.setValue(account);
  }


  getDatabases() {
    this.httpService.get('/management/databases').toPromise().then((res: Array<Database>) => {
      this.databases = res;
      this.databasesView = this.databases;
    });
  }

  filterDatabase(value: string) {
    let regexp = new RegExp(value, 'i');
    this.databasesView = this.databases.filter(({ codename }) => codename.match(regexp));
  }
}
