import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ManagementService } from 'src/app/services/management.service';
import { Company } from 'src/app/models/management/company';

// declare var google: any;

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})

export class CompaniesComponent implements OnInit {

  companies: Array<Company>;
  companiesView: Array<Company>;
  onUpdate: boolean;

  constructor(private managementService: ManagementService) {
  }

  ngOnInit() {
    this.onUpdate = false;
    this.fillData();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      console.log(form.value);
      let company_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.managementService.updateDoc('company', company_id, form.value).then(res => {
        this.fillData();
        this.onUpdate = false;
        form.resetForm();
      }).catch(err => {
        console.log(err);
      })
    } else {
      this.managementService.createDoc('company', form.value).then(res => {
        this.fillData();
        form.resetForm();
      }).catch(err => {
        console.log(err);
      })
    }
  }

  deleteCompany(company: Company) {
    console.log(company);
    this.managementService.deleteDoc('company', company._id).then((res: any) => {
      this.companiesView = this.companiesView.filter(obj => obj._id !== company._id);
    });
  }

  editCompany(companyForm: NgForm, company: Company) {
    this.onUpdate = true;
    companyForm.setValue(company);
  }

  filterCompany(value: string) {
    let regexp = new RegExp(value, 'i');
    this.companiesView = this.companies.filter(({ name }) => name.match(regexp));
  }

  fillData() {
    this.managementService.queryDocs('companies').then((res: Array<Company>) => {
      this.companies = res;
      this.companiesView = this.companies;
    });
  }
}