import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from 'src/app/models/management/store';
import { ManagementService, cuisines, categories, placeOptions } from 'src/app/services/management.service';
import { Database } from 'src/app/models/management/database';
import { HttpService } from 'src/app/services/http.service';
import { Account } from 'src/app/models/management/account';
import { Company } from 'src/app/models/management/company';

// declare var google: any;

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})

export class StoresComponent implements OnInit {
  stores: Array<Store>;
  storesView: Array<Store>;

  onUpdate: boolean;

  accounts: Array<Account>;
  databases: Array<Database>
  companies: Array<Company>;

  cuisines: Array<string>;
  categories: Array<string>;
  placeOptions: Array<string>;

  isChecked: boolean = false;

  opening: string = "08:00";
  closing: string = "23:00";

  address: any;
  cordinates: any;

  mapZoom: number;
  mapDrag: boolean;

  logo: any;
  avatar: any;

  placeOpt: string = "Yeni bir özellik eklemek ister misiniz?";
  categoryOpt: string = "Yeni bir kategori eklemek ister misiniz?"
  kitchenOpt: string = "Yeni bir mutfak eklemek ister misiniz?"

  hasTapdk: boolean = false;
  isTapdkApproved;
  isLoading = false;

  constructor(private httpService: HttpService, private managementService: ManagementService) {
    this.cuisines = cuisines;
    this.categories = categories;
    this.placeOptions = placeOptions;
  }

  ngOnInit() {
    this.onUpdate = false;
    this.fillData();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let store_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.managementService.updateDoc('store', store_id, form.value).then(res => {
        this.fillData();
        this.onUpdate = false;
        form.resetForm();
        this.isChecked = false;
      })
    } else {
      this.managementService.createDoc('store', form.value).then(res => {
        this.fillData();
        form.resetForm();
        this.isChecked = false;
      })
    }
  }

  deleteStore(store_id: string) {
    this.managementService.deleteDoc('store', store_id).then((res: any) => {
      this.storesView = this.storesView.filter(store => store._id !== store_id);
    });
  }

  editStore(storeForm: NgForm, store: Store) {
    this.onUpdate = true;
    try {
      storeForm.setValue(store);
    } catch (error) {
      store.slug = '';
      store.notes = '';
      store.company = '';
      store.supervisory = '';
      storeForm.setValue(store);
    }
  }

  filterStores(value: string) {
    let regexp = new RegExp(value, 'i');
    this.storesView = this.stores.filter(({ name }) => name.match(regexp));
  }

  fillData() {
    this.managementService.queryDocs('stores').then((res: Array<Store>) => {
      this.stores = res;
      this.storesView = this.stores;
    });
    this.managementService.queryDocs('accounts').then((res: Array<Account>) => {
      this.accounts = res;
    })
    this.managementService.queryDocs('databases').then((res: Array<Database>) => {
      this.databases = res;
    })
    this.managementService.queryDocs('companies').then((res: Array<Company>) => {
      this.companies = res;
    })
  }

  changeProhibition(value: 'Yok' & 'Var'){
    if(value == 'Var'){
      this.hasTapdk = true;
    }else{
      this.hasTapdk = false;
    }
  }

  detectProhibition(tapdkno:string){
    this.isLoading = true;
    this.httpService.get('/management/utils/tapdk/' + tapdkno).toPromise().then(res => {
      this.isTapdkApproved = true;
      this.isLoading = false;
    }).catch(err => {
      this.isTapdkApproved = false;
      this.isLoading = false;
    })
  }

}