import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'page-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {

  address: any;

  constructor(private httpService: HttpService) {
    this.address = { countries: [], cities: [], provinces: [], districts: [], streets: [] };
  }

  ngOnInit() {
  }

  getAddress(country?, city?, province?, district?, street?) {
    if (country) {
      let countryText = country.options[country.options.selectedIndex].text;
      if (city) {
        let cityText = city.options[city.options.selectedIndex].text;
        if (province) {
          let provinceText = province.options[province.options.selectedIndex].text;
          if (district) {
            let districtText = district.options[district.options.selectedIndex].text;
            if (street) {
              let streetText = street.options[street.options.selectedIndex].text;
            } else {
              this.httpService.get(`/management/address/${country.value}/${city.value}/${province.value}/${district.value}`).toPromise().then(streets => {
                this.address.streets = streets;
              });
            }
          } else {
            this.httpService.get(`/management/address/${country.value}/${city.value}/${province.value}`).toPromise().then(districts => {
              this.address.districts = districts;
            });
          }
        } else {
          this.httpService.get(`/management/address/${country.value}/${city.value}`).toPromise().then(provinces => {
            this.address.provinces = provinces;
          });
        }
      } else {
        this.httpService.get(`/management/address/${country.value}`).toPromise().then(cities => {
          this.address.cities = cities;
        })
      }
    } else {
      this.httpService.get(`/management/address`).toPromise().then(countries => {
        this.address.countries = countries;
      })
    }
  }

}
