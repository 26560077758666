import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  errors: Array<any>;
  errorLogs: Array<any>;
  accessLogs: Array<any>;
  selectedErrorType: number = 0;
  constructor(private httpService: HttpService) { }

  ngOnInit() {
    // this.httpService.get('/management/utils/logs/access').toPromise().then(accessLogs => {
    //   this.accessLogs = accessLogs;
    // })
    this.httpService.get('/management/utils/logs/errors?limit=2000').toPromise().then((errorLogs: Array<any>) => {
      this.errors = errorLogs;
      this.errorLogs = errorLogs.sort((a, b) => b.timestamp - a.timestamp);
    })
  }

  filterErrors(type: number) {
    this.selectedErrorType = type;
    this.errorLogs = this.errors.filter(obj => obj.log_type == type);
    this.errorLogs = this.errorLogs.sort((a, b) => b.timestamp - a.timestamp);
  }

}