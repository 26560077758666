import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';

///// Services
import { HttpService } from './services/http.service';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { StoreService } from './services/store.service';
import { TextNodeService, ShapeService } from './components/blueprint/blueprint.service';

///// Components
import { AppComponent } from './app.component';
import { BlueprintComponent } from './components/blueprint/blueprint.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { UsersComponent } from './pages/users/users.component';
import { AddressesComponent } from './pages/addresses/addresses.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { StoresComponent } from './pages/stores/stores.component';
import { DatabasesComponent } from './pages/databases/databases.component';
import { LoginComponent } from './pages/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProducersComponent } from './pages/producers/producers.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { FloorPlanComponent } from './pages/floorplan/floorplan.component';
import { SuppliersComponent } from './pages/suppliers/suppliers.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { ProductsComponent } from './pages/products/products.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { PicturesComponent } from './pages/pictures/pictures.component';
import { MapComponent } from './pages/map/map.component';
import { OwnersComponent } from './pages/owners/owners.component';
import { SubCategoriesComponent } from './pages/sub-categories/sub-categories.component';
import { BrandsComponent } from './pages/brands/brands.component';
import { AddressComponent } from './components/address/address.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { MenuComponent } from './pages/menu/menu.component';
import { SessionsComponent } from './pages/sessions/sessions.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { AgreementsComponent } from './pages/agreements/agreements.component';
import { InvoiceTraceComponent } from './pages/invoice-trace/invoice-trace.component';
import { AgreementTraceComponent } from './pages/agreement-trace/agreement-trace.component';
import { DateComponent } from './components/date/date.component';

///// Pipes
import { PhonePipe } from './pipes/phone.pipe';
import { PricePipe } from './pipes/price.pipe';
import { TimeAgoPipe } from './pipes/timeago.pipe';

///// Third-Party
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ImageComponent } from './components/image/image.component';
import { CheckboxDirective } from './directives/checkbox.directive';
import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { CommentComponent } from './pages/comment/comment.component';
import { ContactComponent } from './pages/contact/contact.component'
// import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    UsersComponent,
    AccountsComponent,
    StoresComponent,
    DatabasesComponent,
    LoginComponent,
    SidebarComponent,
    FooterComponent,
    ImageComponent,
    CheckboxDirective,
    OwnersComponent,
    AddressesComponent,
    SuppliersComponent,
    OrdersComponent,
    ProductsComponent,
    InvoicesComponent,
    PicturesComponent,
    MapComponent,
    ProducersComponent,
    CategoriesComponent,
    SubCategoriesComponent,
    BrandsComponent,
    BlueprintComponent,
    FloorPlanComponent,
    AddressComponent,
    PhonePipe,
    PricePipe,
    TimeAgoPipe,
    MenuComponent,
    SessionsComponent,
    CampaignsComponent,
    CompaniesComponent,
    AgreementsComponent,
    InvoiceTraceComponent,
    AgreementTraceComponent,
    DateComponent,
    CommentComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    ImageCropperModule,
    NgxJsonViewerModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCcRjnHqayHzuia_biO4XDsNPbpxZE690A'
    }),
  ],
  providers: [
    HttpService,
    AuthService,
    StorageService,
    StoreService,
    TextNodeService,
    ShapeService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
