import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { NgModel, CheckboxControlValueAccessor, NgForm } from '@angular/forms';

@Directive({
  selector: '[type="checkbox"]',
  providers: [NgModel]
})
export class CheckboxDirective {

  constructor(private el: ElementRef<any>, private renderer: Renderer2, private model: NgModel) {
  }

}
