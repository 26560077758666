import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { NgForm } from '@angular/forms';
import { ManagementService } from 'src/app/services/management.service';
import { Store } from 'src/app/models/social/stores';
import { Currency, Invoice, InvoiceItem } from 'src/app/models/management/invoice';
import { Company } from 'src/app/models/management/company';
import { DateComponent } from 'src/app/components/date/date.component';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})

export class InvoicesComponent implements OnInit {

  stores: Array<Store> = [];
  companies: Array<Company> = [];

  hasStore: boolean = false;

  onUpdate: boolean;

  invoices: Array<Invoice> = [];
  invoicesView: Array<Invoice> = [];
  invoiceItems: Array<InvoiceItem> = [];

  startDate: string;
  expiryDate: string;

  dollarCurrency: number;
  euroCurrency: number;
  total: number;
  subTotal: number;
  tax: number;

  @ViewChild("invoiceForm", { static: true }) invoiceForm: NgForm;
  @ViewChild(DateComponent, { static: true }) child: DateComponent;

  constructor(private httpService: HttpService, private managementService: ManagementService) { }

  ngOnInit() {
    this.total = 0;
    this.subTotal = 0;
    this.tax = 0;
    this.onUpdate = false;

    this.fillData();
  }

  ngAfterViewInit() {
    this.startDate = this.child.startDate;
    this.expiryDate = this.child.endDate;
  }

  submitForm(form: NgForm, post: boolean) {
    if (post) {
      if (this.onUpdate) {
        let invoice_id = form.value._id;
        delete form.value._id;
        delete form.value._rev;
        this.managementService.updateDoc('invoice', invoice_id, form.value).then(res => {
          this.fillData();
          form.reset();
          this.onUpdate = false;
        })
      } else {
        this.managementService.createDoc('invoice', form.value).then(res => {
          this.fillData();
          form.reset();
        })
      }
    }
    else {
      let invoiceItem: Invoice = {
        from: undefined,
        to: undefined,
        items: this.invoiceItems,
        total: this.total,
        sub_total: this.subTotal,
        tax_total: this.tax,
        installment: form.value.installment,
        currency_rates: [],
        status: form.value.status,
        type: form.value.type,
        timestamp: Date.parse(form.value.timestamp),
        expiry: Date.parse(form.value.expiry),
      };

      // for (const key in form.value) {
      //   console.log(form.value[key]);
      // }

      // this.campaigns.push(form.value);
      // this.campaignsView = this.campaigns;
    }
  }

  setDate(date: Array<string>) {
    if (date) {
      this.startDate = date[0];
      this.expiryDate = date[1];
    }
    else return;
  }

  changeStore(store) {
    console.log(store);
  }

  deleteInvoice(invoice_id: string) {
    this.httpService.delete('/management/invoice/' + invoice_id).toPromise().then((res: any) => {
      this.invoicesView = this.invoicesView.filter(invoices => invoices._id !== invoice_id);
    });
  }

  editInvoice(invoicesForm: NgForm, invoices: Invoice) {
    this.onUpdate = true;
    invoicesForm.setValue(invoices);
  }

  filterInvoices(value: string) {
    let regexp = new RegExp(value, 'i');
    this.invoicesView = this.invoices.filter(({ _id }) => _id.match(regexp));
  }

  // Declare InvoiceItem object invoiceObj and then push it to the InvoiceItems:invoiceItems array
  addInvoiceItem(name: string, quantity: number, price: number, tax_value: number, discount: number, description: string, currency: Currency) {

    if (!name || !quantity || !price || !tax_value) {
      alert("İsim, adet, fiyat ve KDV değerleri boş bırakılamaz!");
      return;
    }

    let invoiceItemObject: InvoiceItem = {
      name: name,
      quantity: quantity,
      price: price,
      tax_value: tax_value,
      discount: discount,
      description: description,
      currency: currency,
      total_price: 0,
      total_tax:0
    }

    this.invoiceItems.push(invoiceItemObject);
    this.calculateItemTotal(invoiceItemObject);
  }

  clearInputFields(productName: { value: string; }, productQuantity: { value: string; }, productUnitPrice: { value: string; }, productAddedTax: { value: string; }, productDiscount: { value: string; }, productDescription: { value: string; }, productCurrency: { value: string; }) {
    productName.value = '';
    productQuantity.value = '';
    productUnitPrice.value = '';
    productCurrency.value = 'TRY';
    productAddedTax.value = '';
    productDescription.value = '';
    productDiscount.value = '';
  }

  deleteInvoiceItem(index) {
    this.invoiceItems.splice(index, 1);
    this.calculateInvoiceTotal();
  }

  // Calculates total price and tax for the Invoice and changes the InvoiceItem object's currency to TRY
  calculateItemTotal(item: InvoiceItem) {

    let currencyTotalTry: number = 0;

    switch (item.currency.toString()) {

      case "USD":
        if (item.discount) {
          currencyTotalTry = item.quantity * item.price * this.dollarCurrency;
          item.total_price = currencyTotalTry - (currencyTotalTry * item.discount / 100);
          item.total_tax = item.total_price * item.tax_value / 100;
        }
        else {
          currencyTotalTry = item.quantity * item.price * this.dollarCurrency;
          item.total_price = currencyTotalTry;
          item.total_tax = item.total_price * item.tax_value / 100;
        }
        break;

      case "EUR":
        if (item.discount) {
          currencyTotalTry = item.quantity * item.price * this.euroCurrency;
          item.total_price = currencyTotalTry - (currencyTotalTry * item.discount / 100);
          item.total_tax = item.total_price * item.tax_value / 100;
        }
        else {
          currencyTotalTry = item.quantity * item.price * this.euroCurrency;
          item.total_price = currencyTotalTry;
          item.total_tax = currencyTotalTry * item.tax_value / 100;
        }
        break;
      case "TRY":
        if (item.discount) {
          currencyTotalTry = item.quantity * item.price;
          item.total_price = currencyTotalTry - (currencyTotalTry * item.discount / 100);
          item.total_tax = item.total_price * item.tax_value / 100;
        }
        else {
          item.total_price = item.quantity * item.price;
          item.total_tax = item.total_price * item.tax_value / 100;
        }
        break;

      default:
        throw new Error("Invalid currency option has been passed");
    }

    this.calculateInvoiceTotal();
  }


  // Helper function that calculates invoice object's total , sub total and tax prices
  calculateInvoiceTotal() {
    this.total = 0;
    this.subTotal = 0;
    this.tax = 0;
    for (let i = 0; i < this.invoiceItems.length; i++) {
      const element = this.invoiceItems[i];
      this.subTotal += element.total_price;
      this.tax += element.total_tax;
    }
    this.total = this.subTotal + this.tax;
  }

  fillData() {
      this.managementService.queryDocs('stores').then((res: Array<Store>) => {
        this.stores = res;
      });
      this.managementService.queryDocs('companies').then((res: Array<Company>) => {
        this.companies = res;
      });
      this.managementService.queryDocs('invoices').then((res: Array<Invoice>) => {
        this.invoices = res;
        this.invoicesView = this.invoices;
      });

      this.httpService.getCurreny().then(data => {
        console.log(data);
        this.dollarCurrency = data["USD"].satis;
        this.euroCurrency = data["EUR"].satis;
      }).catch(err => {
        console.log(err);
      })
  }
}