import { Component, OnInit } from '@angular/core';
import { Campaign } from 'src/app/models/management/campaign';
import { NgForm } from '@angular/forms';
import { ManagementService } from 'src/app/services/management.service';
import { Producer } from 'src/app/models/management/producer';
import { Product } from 'src/app/models/management/product';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  campaigns: Array<Campaign>;
  campaignsView: Array<Campaign>;
  producers: Array<Producer>;
  products: Array<Product>;
  onUpdate: boolean;
  logo: string;

  constructor(private managementService: ManagementService) {
    this.campaigns = [];
    this.campaignsView = [];
    this.producers = [];
  }

  ngOnInit() {
    this.onUpdate = false;
    this.fillData();
  }

  submitForm(form: NgForm) {
      if (this.onUpdate) {
        let campaign_id = form.value._id;
        delete form.value._id;
        delete form.value._rev;
        this.managementService.updateDoc('campaign', campaign_id, form.value).then(res => {
          this.fillData();
          form.reset();
          this.onUpdate = false;
        })
      } else {
        console.log(form.value);
        this.managementService.createDoc('campaign', form.value).then(res => {
          this.fillData();
          form.reset();
        })
      }
  }

  deleteCampaign(campaign_id: string) {
    this.managementService.deleteDoc('campaign', campaign_id).then((res: any) => {
      this.campaignsView = this.campaignsView.filter(campaign => campaign._id !== campaign_id);
    });
  }

  editCampaign(campaignForm: NgForm, campaign: Campaign) {
    this.onUpdate = true;
    campaignForm.setValue(campaign);
  }

  filterCampaigns(value: string) {
    let regexp = new RegExp(value, 'i');
    this.campaignsView = this.campaigns.filter(({ name }) => name.match(regexp));
  }

  changeProducer(account_id: string) {
    this.managementService.queryDocs('producers').then((res: Array<Producer>) => {
      this.producers = res;
    })
  }

  fillData() {
    this.managementService.queryDocs('campaigns').then((res: Array<Campaign>) => {
      this.campaigns = res;
      this.campaignsView = this.campaigns;
    });
    this.managementService.queryDocs('producers').then((res: Array<Producer>) => {
      this.producers = res;
    })
  }
}
