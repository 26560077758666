import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { Category } from 'src/app/models/management/category';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: Array<Category>;
  categoriesView: Array<Category>;
  onUpdate: boolean;

  img: any;

  constructor(private httpService: HttpService) { }

  ngOnInit() {
    this.onUpdate = false;
    this.getCategories();
  }

  submitForm(form: NgForm) {
    if (this.onUpdate) {
      let category_id = form.value._id;
      delete form.value._id;
      delete form.value._rev;
      this.httpService.put('/management/category/' + category_id, form.value).toPromise().then(res => {
        this.getCategories();
        form.reset();
        this.onUpdate = false;
      })
    } else {
      this.httpService.post('/management/category', form.value).toPromise().then(res => {
        this.getCategories();
        form.reset();
      })
    }
  }

  getCategories() {
    this.httpService.get('/management/categories').toPromise().then((res: Array<Category>) => {
      this.categories = res;
      this.categoriesView = this.categories;
    });
  }

  deleteCategory(category_id: string) {
    this.httpService.delete('/management/category/' + category_id).toPromise().then((res: any) => {
      this.categoriesView = this.categoriesView.filter(category => category._id !== category_id);
    });
  }

  editCategory(categoryForm: NgForm, category: Category) {
    this.onUpdate = true;
    categoryForm.setValue(category);
  }


  filterCategories(value: string) {
    let regexp = new RegExp(value, 'i');
    this.categoriesView = this.categories.filter(({ name }) => name.match(regexp));
  }
}
