import { Component, OnInit } from '@angular/core';
import { ManagementService } from 'src/app/services/management.service';
import { Store } from 'src/app/models/management/store';
import { Floor, Table } from 'src/app/models/store/pos/table.mock';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.component.html',
  styleUrls: ['./floorplan.component.scss']
})
export class FloorPlanComponent implements OnInit {

  stores: Array<Store> = [];
  floors: Array<Floor> = [];
  tables: Array<Table> = [];
  tablesView: Array<Table> = [];

  selectedFloor: string;

  onUpdate: boolean;

  constructor(private storeService: StoreService, private managementService: ManagementService) { }

  ngOnInit() {
    this.getStores();
  }

  changeStore(store_id: string) {
    this.storeService.queryStoreDocs(store_id, 'db_name=floors').then((res: Array<Floor>) => {
      this.floors = res;
    });
    this.storeService.queryStoreDocs(store_id, 'db_name=tables').then((res: Array<Table>) => {
      this.tables = res;
    });
  }

  selectFloor(floor: Floor) {
    this.selectedFloor = floor._id;
    this.tablesView = this.tables.filter(({ floor_id }) => floor_id == floor._id);
  }

  getStores() {
    this.managementService.queryDocs('stores').then((stores: Array<Store>) => {
      this.stores = stores;
    });
  }

  submitForm(form: any) {

  }
}